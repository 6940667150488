<template>
  <div id="summary" class="card card-body">
    <div class="row align-items-center">
      <div class="summary__image-column">
        <div class="position-relative ratio ratio-1x1 border-radius-lg overflow-hidden">
          <template v-if="visualImage || visualImageData">
            <img
              :alt="title"
              class="summary-visual__image"
              :src="visualImage || visualImageData"
            />

            <button
              class="summary-visual-remove position-absolute top-0 start-0 bottom-0 p-0 border-0
                     w-100 bg-transparent"
              @click="removevisualImage"
            >
              <div class="summary-visual-remove__background w-100 h-100 bg-danger" />

              <i
                class="position-absolute top-0 start-0 bottom-0 end-0 d-flex justify-content-center
                       align-items-center material-icons text-light fs-2"
              >
                delete_outline
              </i>
            </button>
          </template>

          <label
            v-else
            class="d-flex justify-content-center align-items-center w-100 h-100 m-0 bg-gradient-dark
                   cursor-pointer"
            for="visualImageData"
          >
            <i class="d-block material-icons text-secondary fs-2">
              add_a_photo
            </i>
            
            <input
              id="visualImageData"
              accept="image/*"
              capture="user"
              class="d-none"
              type="file"
              @change="visualImageChanged"
            />
          </label>
        </div>
      </div>
      
      <div class="col">
        <h5 class="mb-1 font-weight-bolder">
          {{ title }}
        </h5>

        <p class="mb-0 font-weight-normal text-sm">
          {{ venueName }}

          <template v-if="venueName && parsedDateTimeRange">
            //
          </template>

          {{ parsedDateTimeRange }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { parseDateTimeRange } from '@/helpers/parsers';

export default {
  name: 'ProjectItemSummary',

  props: {
    showUpdate: {
      type: Boolean,
      default: true,
    },
    createdDateTime: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true,
    },
    visualImage: {
      type: String,
      required: true,
    },
    visualImageData: {
      type: String,
      default: '',
    },
  },

  emits: [
    'update:visualImage',
    'update:visualImageData',
  ],

  computed: {
    parsedDateTimeRange() {
      return this.parseDateTimeRange(this.startDateTime, this.endDateTime);
    }
  },

  methods: {
    parseDateTimeRange,

    visualImageChanged({ target }) {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        this.$emit('update:visualImageData', target.result);
      };
      reader.readAsDataURL(target.files[0]);
    },

    removevisualImage() {
      if (!confirm('Weet je zeker dat je de afbeelding wil verwijderen?')) {
        return;
      }

      this.$emit('update:visualImage', '');
      this.$emit('update:visualImageData', '');
    },
  },
};
</script>

<style scoped lang="scss">
.summary {

  &__image {
  
    &-column {
      width: 120px;
    }
  }
}

.summary-visual {

  &__image {
    aspect-ratio: 1;
    object-fit: cover;
  }

  &-remove {
    transition: opacity .2s;

    &:not(:hover) {
      opacity: 0;
    }

    &__background {
      opacity: .75;
    }
  }
  .user-selector-dropdown {
    float:right;
    width:250px;
    .user-profile-image {
      margin-left: -3px;
    }
  }
}
</style>
