export function getDateString(date) { return parseDateString(date); }

export function getTimeString(date) { return parseTimeString(date); }

function parseDateString(date) {
    // Return empty string if date is invalid
    if(!(date instanceof Date) || isNaN(date)) return ''

    const year = date.getFullYear()
    const month = parseIntAs2CharacterString(date.getMonth() + 1)
    const day = parseIntAs2CharacterString(date.getDate())

    return `${year}-${month}-${day}`
}

function parseTimeString(date) {
    // Return empty string if date is invalid
    if(!(date instanceof Date) || isNaN(date)) return ''

    const hours = parseIntAs2CharacterString(date.getHours())
    const minutes = parseIntAs2CharacterString(date.getMinutes())

    return `${hours}:${minutes}`;
}

const parseIntAs2CharacterString = value => value.toString().padStart(2, '0')