<template>
  <div class="row mt-4">
    <div class="col-12">
      <material-input
        id="title"
        v-model="titleHandler"
        is-required
        variant="static"
        label="Titel"
        placeholder="Vul een titel in"
      />
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <rich-text-editor
        id="description"
        v-model:content="descriptionHandler"
        title="Omschrijving"
      />
    </div>
  </div>

  <DateTimePicker
    v-model:date-time="publishDateTimeHandler"
    label="Gepubliceerd op"
    @update:date-time="value => emit('update:publishDateTime', value)"
  />
</template>

<script>
import { createVModelHandlers } from '@/helpers/vue';
import MaterialInput from '@/components/MaterialInput.vue';
import RichTextEditor from '@/components/UI/RichTextEditor.vue';
import DateTimePicker from '../../UI/DateTimePicker.vue';

export default {
  name: 'BasicInfo',

  components: {
    MaterialInput,
    DateTimePicker,
    RichTextEditor
  },

  props: {
    description: {
      type: String,
      required: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    publishDateTime: {
      type: String,
      required: true
    }
  },

  emits: [
    'update:description',
    'update:title',
    'update:publishDateTime',
  ],

  computed: {
    ...createVModelHandlers(
      'description',
      'title',
      'publishDateTime'
    )
  }
};
</script>