<template>
  <label v-if="title">{{ title }}</label>
  <Editor 
    :id="id" 
    v-model="contentHandler"
    :init="config" 
    model-events="change focus" 
    @selectionChange="handleEditorChange"
    :api-key="api_key"

  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "RichTextEditor",
  components: {
    Editor
  },
  props: {
    id: { type: String, required: true },
    content: { type: String, default: "" },
    title: { type: String, default: undefined }
  },
  emits: [
    "update:content"
  ],
  data() {
    return {
      api_key: "tsjzm5754rougaqoft4v633kwy0pqyyzsxibigrdx0j4u647",
      config: {
        menubar: false,
        toolbar: [ // Groups of toolbar functions, seperated into groups.
          "undo redo",
          "bold italic",
          "h1 h2 h3 h4 h5",
          "alignleft aligncenter alignright",
          "bullist numlist",
          "link",
          "pastetext",
          "code"
        ].join("|"),
        plugins: ["link", "lists", "paste", "code"].join(' '),

        // Paste settings:
        paste_remove_styles: true,
        paste_remove_styles_if_webkit: true,
        paste_preprocess: this.paste_preprocess,
        paste_auto_cleanup_on_paste : true,
        paste_remove_spans: true,
        paste_retain_style_properties: false,
      },
    };
  },
  computed: {
    contentHandler: {
      get() { return this.content },
      set(value) { this.$emit('update:content', value) }
    },
  },
  methods:
  {
    // Gets the cleaned up HTML from the editor.
    // getContent removes all of the WYSIWYG-classes and styling.
    handleEditorChange(_event, editor) {
      this.contentHandler = editor.getContent();
    },

    paste_preprocess(plugin, args) 
    {
      let content = args.content;
      // Replace all escaped html character with their unescaped equivalent.
      content = content.replace(RegExp(/&lt;/g), '<');
      content = content.replace(RegExp(/&gt;/g), '>');
      
      // Split content into lines
      let lines = []

      for(let line of content.split('<br />'))
      {
        // Strip all HTML tags from the line.
        line = line.replace(RegExp(/<[^>]+>/g), '')

        // Strip spaces/tabs at before any text
        line = line.replace(RegExp(/^[\s\t]+/gm), '')

        if(line.length > 0) lines.push(line.trim())
      }

      args.content = lines.join('<br/>')
    },
    stripHtmlTags(html) {
      const regex = RegExp(/&gt;[^&lt;]+&lt;/g)
      
      let strippedText = ""
      for(const line of html.split('\n'))
      {
        strippedText += line.replace(regex, '');
      }

      return strippedText
    }
  }
}
</script>