<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>

  <div class="col-lg-9 mt-lg-0 mt-4">
    <project-item-summary
      v-model:visual-image="fields.visualImage"
      v-model:visual-image-data="fields.visualImageData"
      :created-date-time="fields.createDate"
      :title="fields.title"
    />

    <form @submit.prevent="submit">
      <base-fieldset
        id="basic-info"
        title="Algemeen"
      >
        <basic-info
          v-model:description="fields.description"
          v-model:title="fields.title"
          v-model:url="fields.url"
          v-model:publishDateTime="fields.publishDate"
          :show-update="!isNewItem"
        />
      </base-fieldset>

      <div class="card mt-4">
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Opslaan</h5>
            </div>
            <div class="w-50 text-end">
              <material-button
                :color="submitButtonColor"
                variant="gradient"
                class="float-end mt-4 mb-0 mx-4"
              >
                {{ submitButtonText }}
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!isNewItem"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijder dit groepsbericht</h5>
              <p class="text-sm mb-0">
                Wanneer een groepsbericht verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete bericht
              </material-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { add, edit, get, remove } from '@/api/providers/projectItems';
import BaseFieldset from '@/components/Forms/BaseFieldset.vue';
import BasicInfo from '@/components/News/Edit/BasicInfo.vue';
import ProjectItemSummary from '../../components/ProjectOverview/Edit/ProjectItemSummary.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import SideNav from '@/components/UI/SideNav.vue';
import { createYesNoSweetAlert } from '@/helpers/sweetalert'

export default {
  name: 'ProjectItemEdit',

  components: {
    BaseFieldset,
    BasicInfo,
    ProjectItemSummary,
    MaterialButton,
    SideNav
  },

  data: () => ({
    fields: {
      title: '',
      description: '',
      publishDate: '',
      organizationId: 0,
      visualImageData: '',
      url: '',
    },
    loading: false,
  }),

  computed: {
    ...mapGetters('account', [
      'organizationId',
    ]),

    isNewItem() {
      return this.$route.params?.projectItemId === undefined;
    },

    navigationItems() {
      return [
        {
          href: '#summary',
          icon: 'image',
          name: 'Samenvatting',
        },
        {
          href: '#basic-info',
          icon: 'notes',
          name: 'Algemeen',
        }
      ];
    },

    submitData() {
      return {
        ...this.fields,
        projectId: this.fields.projectId ?? this.$route.params?.projectId,
        projectItemId: this.$route.params?.projectItemId,
        organizationId: this.organizationId,
      };
    },

    submitButtonColor() {
      return this.isNewItem ? 'primary' : 'dark'
    },

    submitButtonText() {
      return this.isNewItem ? 'Opslaan' : 'Update gegevens'
    }
  },

  mounted() {
    this.loadProjectItemData();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    addProjectItem() {
      add(this.submitData)
        .then(this.handleSaveSuccess)
        .catch((error) => { if(typeof error.default === 'function') error.default() })
        .then(() => {
          this.loading = false;
        });
    },

    editProjectItem() {
      edit(this.submitData)
        .then(this.handleSaveSuccess)
        .catch((error) => { if(typeof error.default === 'function') error.default() })
        .then(() => {
          this.loading = false;
        });
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.fields[key] = value;
      });
    },

    handleRemoveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is verwijderd.`,
        icon: {
          component: 'event',
        },
      });

      this.navigateToProjectOverview();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is opgeslagen.`,
        icon: {
          component: 'news',
        },
      });

      this.navigateToProjectOverview();
    },

    loadProjectItemData() {
      if (this.isNewItem) {
        return;
      }

      get(this.$route.params.projectItemId)
        .then(this.handleGetSuccess)
        .catch((error) => { if(typeof error.default === 'function') error.default() })
    },

    navigateToProjectOverview() {
      this.$router.push({
        name: 'project-overview',
        params: { projectId: this.$route.params?.projectId}
      });
    },

    remove() {
      createYesNoSweetAlert(
        `Weet u zeker dat u de het projectbericht <b>${this.fields.title}</b> wilt verwijderen?`,
        () => {
          remove(this.$route.params.projectItemId)
            .then(() => {
              this.addNotification({
                description: `${this.fields.title} is verwijderd.`,
                icon: {
                  component: 'person_remove',
                },
              });

              this.navigateToProjectOverview();
            })
            .catch((error) => error.default());
        }
      );
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      if (this.isNewItem) {
        this.addProjectItem();
      } else {
        this.editProjectItem();
      }
    },
  },
};
</script>
