<template>
    <div class="row mt-4">
      <div class="col-6">
        <material-input
          id="publish-date"
          v-model="dateHandler"
          is-required
          variant="static"
          :label="label"
          type="date"
          @change="dateChangeCallback"
        />
      </div>
  
      <div class="col-6">
        <material-input
          id="publish-time"
          v-model="timeHandler"
          is-required
          variant="static"
          label="&nbsp;"
          type="time"
          @change="timeChangeCallback"
        />
      </div>
    </div>
  </template>
  
  <script>
  import { createVModelHandlers } from '@/helpers/vue';
  import MaterialInput from '@/components/MaterialInput.vue';
  
  import { getDateString, getTimeString } from '../../helpers/datetime';
  
  export default {
    name: 'DateTimePicker',
  
    components: {
      MaterialInput
    },
  
    props: {
      dateTime: { type: String },
      label: { type: String, default: '' }
    },
  
    emits: [
      'update:date-time',
    ],
  
    data() {
      return {
          updatedInputDate: undefined,
          updatedInputTime: undefined
      }
    },
    
  
    computed: {
      ...createVModelHandlers(
        'date',
        'time'
      ),
  
      date: { 
        // If the date in the input has been changed, get the changed date.
        // Get the original one otherwise
        get() { 
          return this.updatedInputDate !== undefined
            ? this.updatedInputDate
            : getDateString(new Date(this.dateTime))
          },
        set(value) { this.updatedInputDate = value }
      },
      time: { 
        // If the time in the input has been changed, get the changed time.
        // Get the original one otherwise
        get() { 
          return this.updatedInputTime !== undefined
            ? this.updatedInputTime
            : getTimeString(new Date(this.dateTime))
          },
        set(value) { this.updatedInputTime = value }
      },
    },
  
    methods: {
      dateChangeCallback(event) { 
        this.date = event.target.value
        this.emitUpdate()
      },
      timeChangeCallback(event) {
        this.time = event.target.value
        this.emitUpdate()
      },
      emitUpdate() { this.$emit("update:date-time", new Date(`${this.date} ${this.time}`).toISOString()) }
    }
  };
  </script>